import {PureComponent} from "react";

import "./RoadmapEntry.css";

export default class RoadmapEntry extends PureComponent<{date: string; title: string; isLeftAligned: boolean}> {
    render() {
        return (
            <div className={"roadmap-entry " + (this.props.isLeftAligned ? "flex-row-reverse left-timeline" : "right-timeline")}>
                <div className="order-1 w-5/12" />
                <div className={"order-1 w-5/12 px-1 py-4 " + (this.props.isLeftAligned ? "text-right" : "text-left")}>
                    <p className="text-base text-accentDark">{this.props.date}</p>
                    <h4 className="mb-3 font-bold text-lg">{this.props.title}</h4>
                    <div className="text-sm leading-snug">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}