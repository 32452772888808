import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";

import Slider from "react-slick";
import "./slick.min.css";

import snow from "./snow";
import FaqEntry from "./FaqEntry";
import RoadmapEntry from "./RoadmapEntry";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet() ],
    []
  );

  snow.start({
    flakeCount: ('ontouchstart' in window) ? 45 : 150,
    color: "#F0F9FF",
  });

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="close icon" onClick={toggleMenu}/>
        </div>
        <ul>
          <li>
            <a className="text-accent-color" href="/#mint" onClick={toggleMenu}>
              Mint
            </a>
          </li>
          <li>
            <a href="/#roadmap" onClick={toggleMenu}>
              Roadmap
            </a>
          </li>
          <li>
            <a href="/#faq" onClick={toggleMenu}>
              FAQ
            </a>
          </li>
          <li>
            <a href="/#team" onClick={toggleMenu}>
              Team
            </a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://twitter.com/AutisticHerd" rel="nofollow noreferrer" target="_blank">
                <img className="nav-social" src="/icons/twitter.svg" alt="twitter icon" />
              </a>
              <a href="https://discord.gg/S2HsMVnMnx" rel="nofollow noreferrer" target="_blank">
                <img className="nav-social" src="/icons/discord.svg" alt="discord icon" />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="menu icon" />
      </div>
      <nav>
        <div className="nav-container">
          <a className="hide-800 text-accent-color" href="/#mint">
            Mint
          </a>
          <a className="hide-800" href="/#roadmap">
            Roadmap
          </a>
          <a className="hide-800" href="/#faq">
            FAQ
          </a>
          <a className="hide-800" href="/#team">
            Team
          </a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com/AutisticHerd" rel="nofollow noreferrer" target="_blank">
              <img className="nav-social" src="/icons/twitter.svg" alt="twitter icon" />
            </a>
            <a href="https://discord.gg/S2HsMVnMnx" rel="nofollow noreferrer" target="_blank">
              <img className="nav-social" src="/icons/discord.svg" alt="discord icon" />
            </a>
          </div>
        </div>
      </nav>
      <div className="content-wrapper text-primary-color">
        <header className="card" id="mint">
          <div style={{ padding: "0 24px 0 24px 0" }}>
            <h3 className="text-primary-color">Welcome To</h3>
            <h1 className="pb-3 text-secondary-gradient">Special Snowflakes</h1>
            <p className="pb-2 text-primary-color">
            Special Snowflakes is the official mint pass issued by the Autistic Reindeer Herd. It will give you whitelist access in the upcoming mints and as a holder you are also qualified to participate in upcoming raffles/giveaways to win prizes. There will be a total amount of 360 Special Snowflake NFTs, so be prepared.
            </p>
            <p className="text-primary-color"> <span className="text-primary-color font-bold">MIND:</span> Autistic Reindeer Herd - Gen 1 holders will be whitelisted for this collection and will also get a discount on the mint price. </p>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletDialogProvider>

                      <Minter
                        candyMachineId={candyMachineId}

                        connection={connection}
                        startDate={startDateSeed}
                        txTimeout={txTimeout}
                        rpcHost={rpcHost}
                      />

                  </WalletDialogProvider>
                </WalletProvider>
              </ConnectionProvider>
            </ThemeProvider>
          </div>
        </header>

        <div id="gallery" className="container">
          <Slider infinite={true} slidesToShow={3} slidesToScroll={3} arrows={false} autoplay={true} autoplaySpeed={500} speed={0}>
            <img src="/img/snowflakes/0.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/1.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/2.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/3.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/4.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/5.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/6.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/7.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/8.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/9.jpg" alt="specialsnowflake nft" />
            <img src="/img/snowflakes/10.jpg" alt="specialsnowflake nft" />
          </Slider>
        </div>

        <div id="roadmap" className="container">
          <h2 style={{ padding: "0 0 24px 0" }}>Roadmap</h2>
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24" style={{marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", justifyItems: "start", marginTop: "3rem", marginBottom: "3rem"}}>
            <div style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}>
              <div style={{position: "relative", overflow: "hidden", paddingLeft: "1rem", paddingRight: "1rem"}}>
                <div style={{border: "solid", borderWidth: "2px", borderColor: "#7dd3fc", position: "absolute", height: "100%", right: "50%"}} />
                <div style={{border: "solid", borderWidth: "2px", borderColor: "#7dd3fc", position: "absolute", height: "100%", left: "50%"}} />             

                <RoadmapEntry date="" title="Twitter - Giveaway" isLeftAligned={true}>
                  <p>Giveaway of a total of 42 discount WL spots</p>
                </RoadmapEntry>
                <RoadmapEntry date="" title="Invite Challenge" isLeftAligned={false}>
                  <ul>
                    <li>Top 10 will get an airdrop of a Special Snowflake NFT</li>
                    <li>Top 5 will be able to participate in a raffle for an Autistic Reindeer - Gen 1</li>
                  </ul>
                </RoadmapEntry>
                <RoadmapEntry date="" title="First holder benefits" isLeftAligned={true}>
                    <p>'Free' mint (only 0.0069 SOL) for Autistic Reindeer - Gen 1 (<a href="https://magiceden.io/marketplace/autistic_reindeer_herd" target="_blank" rel="nofollow noreferrer">Magic Eden</a>) holder or winner of a discount WL spot</p>
                </RoadmapEntry>
                <RoadmapEntry date="" title="80% SOLD" isLeftAligned={false}>
                    <p>Special Snowflake NFT with the highest unique specialness trait will win 2222$ (instant win)</p>
                </RoadmapEntry>
              </div>
            </div>
          </div>
        </div>
        
        <div id="soldout" className="container">
          <h2 style={{ margin: "-10px 0 0px 0" }}>SOLD OUT</h2>
          <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24" style={{marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", justifyItems: "start", marginTop: "3rem", marginBottom: "3rem"}}>
            <div style={{width: "100%", marginLeft: "auto", marginRight: "auto"}}>
              <div style={{position: "relative", overflow: "hidden", paddingLeft: "1rem", paddingRight: "1rem"}}>
                <div style={{border: "solid", borderWidth: "2px", borderColor: "#7dd3fc", position: "absolute", height: "100%", right: "50%"}} />
                <div style={{border: "solid", borderWidth: "2px", borderColor: "#7dd3fc", position: "absolute", height: "100%", left: "50%"}} />
                
                <RoadmapEntry date="" title="Raffle" isLeftAligned={true}>
                  <ul style={{listStylePosition: "inside"}}>
                    <li>5x1000$ (Autistic Reindeer - Gen 1 holder)</li>
                    <li>10x500$ (Special Snowflake holder)</li>
                  </ul>
                </RoadmapEntry>

                <RoadmapEntry date="" title="Dutch Charity Auction of 1/1 NFT" isLeftAligned={false}>
                  <ul style={{listStylePosition: "inside"}}>
                    <li>Unique function</li>
                    <li>Unique discord role</li>
                    <li>Community will vote for the donation target</li>
                  </ul>
                </RoadmapEntry>
                <RoadmapEntry date="" title="Future" isLeftAligned={true}>
                  <p>Take a look at the <a href="https://autistic-reindeer-herd.com/" target="_blank" rel="nofollow noreferrer">Main Roadmap</a></p>
                  <p>As time goes on, there will be more exciting giveaways and other stuff going on.</p>
                </RoadmapEntry>
              </div>
            </div>
          </div>
        </div>



        <div id="faq" className="container faq">
          <h2 style={{ padding: "0 0 24px 0" }}>FAQ</h2>
          <div className="faq-entries-container">
            <FaqEntry question="What is Special Snowflakes?">
              <p>Special Snowflake is a collection of 360 uniquely generated NFTs on the Solana blockchain. It will give you whitelist access in the upcoming mints and as a holder you are also qualified to participate in upcoming raffles/giveaways to win prizes.</p>
            </FaqEntry>
            <FaqEntry question="How was the Special Snowflakes collection created?">
              <p>The origin of the Special Snowflakes is the original <a href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/31822570300706088528545395811403920999992651616153096900226446458519793172481" target="_blank" rel="nofollow noreferrer">Infinite Snowflake</a>. It took me about 2 days to render the original Infinite Snowflake with <a href="https://users.cg.tuwien.ac.at/zsolnai/gfx/smallpaint/" target="_blank" rel="nofollow noreferrer">Smallpaint</a>. It visualizes the infinite Koch snowflake combined wit recursive reflection, which occurs if two mirrors are vis-a-vis. This resulting snowflake was then Hue-shifted 360 times by 1 degree. To determine the rarity or in this case "specialness", I created the pascal's triangle with 69 rows and have chosen 360 random pascal numbers of the triangle to create the specialness.</p>
            </FaqEntry>
            <FaqEntry question="How do I purchase a Special Snowflake?">
              <p style={{ padding : "0 0 10px 0" }}>Just connect your Solana wallet by clicking on "Connect Wallet" and follow the instructions.</p>
              <ol>
                <li>Get the Phantom wallet extension for your browser (Chrome or Firefox is recommended). <a href="https://phantom.app">https://phantom.app</a></li>
                <li>Purchase SOL, for example on exchanges like Crypto.com, Binance, Coinbase, Huobi or FTX and send it to your wallet, which you created in step one.</li>
                <li>Now connect your wallet by clicking on "Connect wallet"</li>
                <li>Click the "Mint" button and approve the transaction in your wallet dialogue.</li>
              </ol>              
            </FaqEntry>
            <FaqEntry question="How much does a Special Snowflake cost?">
              <p>Regular 0.6969 SOL and 0.0069 SOL as discount price.</p>
            </FaqEntry>
            <FaqEntry question="How many NFT are there for mint in total?">
              <p>There is a total number of 360 Special Snowflakes to mint.</p>
            </FaqEntry>
            <FaqEntry question="How will I get the NFT after purchase">
              <p>After successful mint you will see a confirmation on your screen. Shortly afterwards you should be able to see your Special Snowflake in your wallet.</p>
            </FaqEntry>
            <FaqEntry question="What wallet should I use?">
              <p>I would recommend Phantom wallet: <a href="https://phantom.app" target="_blank" rel="nofollow noreferrer">phantom.app</a></p>
            </FaqEntry>
            <FaqEntry question="Do you collect royalities?">
              <p>Yes, I collect an industry-standard of 5% royalty from every sale on secondary markets.</p>
            </FaqEntry>
            <FaqEntry question="Who is behind the Special Snowflakes collection?">
              <p>Basically me, an autistic person that likes reindeers. Unfortunately, I lack of good web dev skills, so I had help from an amazing web dev from Austria to realize the website within time.</p>
            </FaqEntry>
            <FaqEntry question="Terms">
            <h3>Terms &amp; Conditions</h3>
            <p>Special Snowflakes is a collection of digital artworks (NFTs) running on the Solana blockchain. This website is only an interface allowing participants to buy digital collectibles. Users are entirely responsible for the safety and management of their own private Solana wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the Special Snowflakes program runs on the Solana blockchain, there is no ability to undo, reverse, or restore any transactions. This website and its connected services are provided "as is" and "as available" without a warranty of any kind. By using this website, you are accepting sole responsibility for any and all transactions involving the Special Snowflake digital collectibles. Finally, we want to state that NFT are precluded from being taken back and exchanged.</p>
            <h3>Ownership</h3>
            <ul>
              <li>You Own the NFT
                <p>Each Special Snowflake is an NFT on the Solana blockchain. When you purchase an NFT, you own the underlying Special Snowflake Token, completely. Ownership of the NFT is mediated entirely by the program and the Solana Network: at no point may we seize, freeze, or otherwise modify the ownership of any Special Snowflake Token.</p>
              </li>
              <li>Personal Use
                <p>Subject to your continued compliance with these Terms, we grant you a worldwide, royalty-free license to use, copy, and display the purchased art, along with any extensions that you choose to create or use, solely for the following purposes:</p>
                <ul>
                  <li>for your own personal, non-commercial use;</li>
                </ul>
              </li>
            </ul>
            </FaqEntry>
          </div>
        </div>

        <div id="team" className="container">
          <h2 style={{ padding: "0 0 24px 0" }}>Team</h2>
          <div style={{display: "flex", justifyContent: "center"}}>
            <div>
              <img src="/img/rudolph.jpg" alt="rudolph reindeer avatar" style={{width: "16rem"}}/>
              <p style={{fontWeight: 800, fontSize: "1.1rem"}}>Rudolph Rentier</p>
              <p style={{fontWeight: 200}}>Mastermind</p>
              <a style={{color: "#7dd3fc", fontWeight: 400}} href="https://twitter.com/RudolphRentier2" rel="nofollow noreferrer" target="_blank"><span>Twitter</span></a>
            </div>
          </div>
        </div>
      </div>

      <footer style={{marginTop: "8rem", marginLeft: "1rem", marginBottom: "0.5rem"}} className="text-secondary-gradient">
        <a href="https://autistic-reindeer-herd.com/legal/legal" rel="nofollow noreferrer" target="_blank">Legal Notice</a> | <a href="https://discord.gg/S2HsMVnMnx" rel="nofollow noreferrer" target="_blank">Discord</a> | <a href="https://twitter.com/AutisticHerd" rel="nofollow noreferrer" target="_blank">Twitter</a>
        <br />
        <a href="https://autistic-reindeer-herd.com" rel="nofollow noreferrer" target="_blank">&copy; 2022 Autistic Reindeer Herd</a>
      </footer>
    </div>
  );
};

export default App;
