import {PureComponent} from "react";

import "./FaqEntry.css";

export default class FaqEntry extends PureComponent<{question: string;}, {expanded: boolean;}> {
    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    render() {
        const question = this.props.question;

        return (
            <>
                <div className="faq-entry">
                    <div className="faq-entry-question">
                        <button type="button" onClick={() => {this.setState({expanded: !this.state.expanded})}}>
                            <span>{question}</span>
                            <span className="faq-entry-question-svg">
                                <svg style={{transform: this.state.expanded ? 'rotate(-180deg)' : 'rotate(0deg)'}} xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="faq-entry-answer" style={{maxHeight: this.state.expanded ? '150ch' : '0'}}>
                        {this.props.children}
                    </div>
                </div>
                <hr />
            </>
        )
    }
}